export function sid (SID) {
  return `${SID.slice(0, 10)}...${SID.slice(-10)}`
}

export function idNum (id) {
  if (id <= 0) {
    return ""
  }
  return id
}
