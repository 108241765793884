<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Websocket Connections</v-toolbar-title>
    </v-toolbar>

    <v-data-table
            :headers="headers"
            :items="items"
            dense
            disable-pagination
            hide-default-footer
    >

      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-left">
            {{ sid(item.SID) }}
          </td>
          <td class="text-right">
            {{ item.Id.toString().padStart(5,'0') }}
          </td>
          <td class="text-center">
            {{ item.Co_id }}
          </td>
          <td class="text-center">
            {{ idNum(item.User_id) }}
          </td>
          <td class="text-center">
            {{ idNum(item.Dev_id) }}
          </td>
          <td class="text-left">
            {{ item.Addr }}
          </td>
          <td class="text-left">
            {{prettyDT(item.Start) }}
          </td>
          <td class="text-left">
            {{ item.Url }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold" colspan="2">Total Websockets: {{ items.length }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import { prettyDT } from '../utils'
  import { sid, idNum } from './utils'

  export default {
    name: "Websockets",
    created () {
      console.log('Created:', this.$options.name)
    },
    beforeDestroy () {
      console.log('BeforeDestroy:', this.$options.name)
    },

    computed: {
      items () {
        return this.$store.getters.websockets
      }
    },

    methods: {
      prettyDT,
      sid,
      idNum
    },

    data () {
      return {
        // table for list of websocket connections
        headers: [
          { text: 'Session ID', align: 'left' },
          { text: 'Id', align: 'right' },
          { text: 'Company', align: 'center' },
          { text: 'User', align: 'center' },
          { text: 'Device', align: 'center' },
          { text: 'Addr', align: 'left' },
          { text: 'Start', align: 'left' },
          { text: 'Url', align: 'left' }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
