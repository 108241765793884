<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Session Watchers</v-toolbar-title>
    </v-toolbar>

    <v-data-table
            :headers="headers"
            :items="items"
            dense
            disable-pagination
            hide-default-footer
    >

      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-left">
            {{ sid(item.sid) }}
          </td>
          <td class="text-left">
            {{prettyDT(item.expires) }}
          </td>
        <tr>
          <td class="font-weight-bold">Total Watchers: {{ items.length }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import { prettyDT } from '../utils'
  import { sid } from './utils'

  export default {
    name: "Watchers",

    created () {
      console.log('Created:', this.$options.name)
    },
    beforeDestroy () {
      console.log('BeforeDestroy:', this.$options.name)
    },

    computed: {
      items () {
        return this.$store.getters.watchers
      }
    },

    methods: {
      prettyDT,
      sid
    },

    data () {
      return {
        // table for list of websocket connections
        headers: [
          { text: 'Session ID', align: 'left', value: 'sid' },
          { text: 'Expires', align: 'left', value: 'expires' }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
