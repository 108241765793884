<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title></v-toolbar-title>
      <v-btn @click="checkAuth" class="mx-6" color="primary">
        <span class="mr-2">Auth</span>
      </v-btn>
      <v-btn @click="checkAuth2" class="mx-6" color="primary">
        <span class="mr-2">Auth2</span>
      </v-btn>
      <v-btn @click="checkCodes" class="mx-6" color="primary">
        <span class="mr-2">Codes</span>
      </v-btn>
    </v-toolbar>

    <websockets></websockets>
    <watchers></watchers>
    <cards></cards>
    <http></http>
  </div>
</template>

<script>
  import Websockets from './Websockets'
  import Watchers from './Watchers'
  import Cards from './Cards'
  import Http from './Http'
  import network from '../../network'

  export default {
    name: "Sessions",
    components: {
      Websockets, Watchers, Cards, Http
    },

    created () {
      console.log('Created:', this.$options.name)
    },
    beforeDestroy () {
      console.log('BeforeDestroy:', this.$options.name)
    },

    methods: {
      checkAuth: network.refreshLogin,
      checkAuth2: network.quickCheck,
      checkCodes: network.checkCodes
    }
  }
</script>

<style scoped>

</style>
